import { get, sum } from 'lodash'
import { getNodeText } from 'utils/react-text'
import { getTextWidth } from 'utils/text-width'

const defaultPadding = 8
const fixedColumnBorder = 1

const getColumnSizes = (columns, data, tableAvailableWidth: number) => {
  const { size: firstColumnSize } = getColumnSize(columns[0], data)

  const columnSizes = columns.map((column) =>
    getColumnSize(
      column,
      data,
      columns.length === 1 || tableAvailableWidth - firstColumnSize <= 0
        ? undefined
        : tableAvailableWidth - firstColumnSize
    )
  )
  const maxTableWidth =
    sum(columnSizes.map(({ maxSize }) => maxSize)) +
    fixedColumnBorder +
    defaultPadding * columnSizes.length * 2
  const isMaxSizeTable = tableAvailableWidth >= maxTableWidth
  const columnWidths = columnSizes.map(({ maxSize, size }) =>
    isMaxSizeTable ? maxSize : size
  )
  const resizableColumnsCount = columnSizes.filter(
    ({ isHardcoded }) => !isHardcoded
  ).length
  const availableSpace =
    tableAvailableWidth -
    sum(columnWidths) -
    fixedColumnBorder -
    defaultPadding * 2
  const availableSpacePerColumn =
    availableSpace > 0 ? availableSpace / resizableColumnsCount : 0

  return columnSizes.map(({ isHardcoded, maxSize, size }) => {
    const calculatedColumnSize = isMaxSizeTable ? maxSize : size
    return isHardcoded
      ? calculatedColumnSize
      : calculatedColumnSize + availableSpacePerColumn
  })
}

const getColumnSize = (column, data, maxWidth: number = Infinity) => {
  if (column.size) {
    return {
      isHardcoded: true,
      size: column.size,
      maxSize: column.size,
    }
  }

  // constants
  const sortIconWidth = 18
  const filterIconWidth = 24
  const cellPaddings = 16

  // calculate header width
  const columnNameWidth = getTextWidth(getNodeText(column.header))
  const columnSortWidth = column.meta?.sortable ? sortIconWidth : 0
  const columnFilterWidth = column.meta?.filter ? filterIconWidth : 0
  const columnHeaderWidth =
    columnNameWidth + columnSortWidth + columnFilterWidth

  // calculate cell width
  const cellTextWidths = data
    .map((row) =>
      column.cell
        ? getNodeText(
            column.cell({
              row: { original: row },
              cell: { getValue: () => get(row, column.accessorKey, '') },
              getValue: () => get(row, column.accessorKey, ''),
            })
          )
        : get(row, column.accessorKey, '')
    )
    .filter((text) => text && text !== '-')
    .map((text) => getTextWidth(text))

  const averageCellWidth = cellTextWidths.length
    ? (sum(cellTextWidths) / cellTextWidths.length) * 1.4
    : 0

  const size = Math.min(
    Math.round(Math.max(columnHeaderWidth, averageCellWidth)) + cellPaddings,
    maxWidth - cellPaddings * 2
  )
  const maxSize = Math.min(
    Math.round(Math.max(columnHeaderWidth, ...cellTextWidths)) + cellPaddings,
    maxWidth - cellPaddings * 2
  )

  return {
    isHardcoded: false,
    size: Math.min(size, maxSize),
    maxSize: Math.max(size, maxSize),
  }
}

export { getColumnSizes, defaultPadding }
