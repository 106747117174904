import { LoanStatus } from 'constants/loan-status'
import { Loan } from 'types'
import { createPath } from 'utils/path'

export const prefix = '/admin'
export const routerPath = {
  root: prefix,
  signIn: `${prefix}/sign-in`,
  invitation: `${prefix}/invitation`,
  verifyEmail: `${prefix}/email/:id/verify`,
  forgotPassword: `${prefix}/forgot-password`,
  forgotPasswordReset: `${prefix}/forgot-password/reset`,
  logout: `${prefix}/logout`,
  dashboard: `${prefix}/dashboard`,
  account: `${prefix}/account`,
  accountMyAccount: `${prefix}/account/my-account`,
  accountMyNotification: `${prefix}/account/my-notification`,
  accountMail: `${prefix}/account/mail`,
  borrowers: `${prefix}/borrowers`,
  borrower: `${prefix}/borrowers/:id`,
  borrowerTab: `${prefix}/borrowers/:id/:tab`,
  borrowerThread: `${prefix}/borrowers/:id/mail/:threadId`,
  personDocument: `${prefix}/:personType/:personId/documents/:id/:versionId?`,
  investors: `${prefix}/investors`,
  investor: `${prefix}/investors/:id`,
  investorTab: `${prefix}/investors/:id/:tab`,
  investorThread: `${prefix}/investors/:id/mail/:threadId`,
  loans: `${prefix}/loans`,
  loan: `${prefix}/loans/:id`,
  loanProduct: `${prefix}/loans/:id/product/:tab?`,
  loanFunding: `${prefix}/loans/:id/funding`,
  loanCharges: `${prefix}/loans/:id/charges`,
  loanDocument: `${prefix}/loans/:loanId/documents/:id/:versionId?`,
  loanTab: `${prefix}/loans/:id/:tab`,
  loanTabEntityId: `${prefix}/loans/:id/:tab/:entityId/:subTab?`,
  loanApplicationPrintable: `${prefix}/loans/:id/application/print`,
  loanSpreadAllocation: `${prefix}/loans/:id/spread-allocation`,
  loanDraw: `${prefix}/loans/:loanId/address/:addressId/draw/:drawId`,
  loanUnitSheet: `${prefix}/loans/:loanId/address/:addressId/unit-sheet`,
  loanThread: `${prefix}/loans/:loanId/mail/:threadId`,
  loanThreadNew: `${prefix}/loans/:loanId/mail/new`,
  servicing: `${prefix}/servicing`,
  servicingLoan: `${prefix}/servicing/:id`,
  servicingLoanProduct: `${prefix}/servicing/:id/product/:tab?`,
  servicingLoanFunding: `${prefix}/servicing/:id/funding`,
  servicingLoanCharges: `${prefix}/servicing/:id/charges`,
  servicingLoanDocument: `${prefix}/servicing/:loanId/documents/:id/:versionId?`,
  servicingLoanTab: `${prefix}/servicing/:id/:tab`,
  servicingLoanTabEntityId: `${prefix}/servicing/:id/:tab/:entityId/:subTab?`,
  servicingLoanDraw: `${prefix}/servicing/:loanId/address/:addressId/draw/:drawId`,
  servicingLoanUnitSheet: `${prefix}/servicing/:loanId/address/:addressId/unit-sheet`,
  servicingLoanThread: `${prefix}/servicing/:loanId/mail/:threadId`,
  servicingLoanThreadNew: `${prefix}/servicing/:loanId/mail/new`,
  vendors: `${prefix}/vendors`,
  vendor: `${prefix}/vendors/:id/:tab?`,
  vendorTab: `${prefix}/vendors/:id/:tab`,
  vendorThread: `${prefix}/vendors/:id/mail/:threadId`,
  reports: `${prefix}/reports`,
  transactionReport: `${prefix}/reports/transactions`,
  paymentReport: `${prefix}/reports/payments`,
  insuranceReport: `${prefix}/reports/insurance`,
  trustBalanceReport: `${prefix}/reports/trust-balance`,
  drawRequestsReport: `${prefix}/reports/draw-requests`,
  drawRequestsReportTab: `${prefix}/reports/draw-requests/:tab`,
  unpaidChargesReport: `${prefix}/reports/unpaid-charges`,
  paidChargesReport: `${prefix}/reports/paid-charges`,
  unpaidInterestReport: `${prefix}/reports/unpaid-interest`,
  trustTransactionsReport: `${prefix}/reports/trust-transactions`,
  tax1098Report: `${prefix}/reports/1098`,
  tax1099Report: `${prefix}/reports/1099`,
  soldLoansReport: `${prefix}/reports/sold-loans`,
  report: `${prefix}/reports/:id`,
  reportCustom: `${prefix}/reports/custom/:id`,
  products: `${prefix}/products`,
  newProduct: `${prefix}/products/new`,
  editProduct: `${prefix}/products/:id/details`,
  product: `${prefix}/products/:id/:tab?`,
  payouts: `${prefix}/payouts`,
  payoutsTab: `${prefix}/payouts/:tab?`,
  payments: `${prefix}/payments/:tab/:id?`,
  paymentsTab: `${prefix}/payments/:tab`,
  offerings: `${prefix}/offerings`,
  offering: `${prefix}/offerings/:id/:tab?`,
  offeringDocument: `${prefix}/offering/:offeringId/documents/:id/:versionId?`,
  statement: `${prefix}/loans/:loanId/statement/:from/:to`,
  quotes: `${prefix}/quotes`,
  quotesTab: `${prefix}/quotes/:tab`,
  quote: `${prefix}/quotes/:tab/:id`,
  quotePreview: `${prefix}/quotes/:tab/:id/preview`,
  receipt: `${prefix}/receipt/:loanId/:paymentId`,
  funds: `${prefix}/funds`,
  fund: `${prefix}/funds/:id/:tab?`,
  fundDistribution: `${prefix}/funds/:id/distributions/:distributionId`,
  fundDocument: `${prefix}/funds/:fundId/documents/:id/:versionId?`,
  settings: `${prefix}/settings`,
  settingsGeneralSettings: `${prefix}/settings/general-settings`,
  settingsPortalSettings: `${prefix}/settings/portal-settings`,
  settingsTeam: `${prefix}/settings/team`,
  settingsRoles: `${prefix}/settings/roles`,
  settingsRole: `${prefix}/settings/roles/:id`,
  settingsNotifications: `${prefix}/settings/notifications`,
  settingsDocTemplates: `${prefix}/settings/doc-templates`,
  settingsWorkspace: `${prefix}/settings/workspace`,
  settingsLenderOfRecord: `${prefix}/settings/lender-of-record`,
  settingsApplication: `${prefix}/settings/application`,
  settingsCustomFields: `${prefix}/settings/custom-fields/:tab?`,
  settingsAutomations: `${prefix}/settings/automations`,
  settingsAutomationsLog: `${prefix}/settings/automations-log`,
  settingsPipeline: `${prefix}/settings/pipeline`,
  investorStatement: `${prefix}/investors/:investorId/statements/:from/:to`,
  marketplace: `${prefix}/marketplace`,
  tasks: `${prefix}/tasks/:tab/:id?`,
  contacts: `${prefix}/contacts`,
  threads: `${prefix}/mail`,
  thread: `${prefix}/mail/:threadId`,
  threadNew: `${prefix}/mail/new`,
}

export const pathTo = (key: keyof typeof routerPath, ...args: string[]) =>
  createPath(routerPath[key], args)

export const isLoanServicing = (status: LoanStatus) =>
  ['servicing', 'liquidated'].includes(status)

export const pathToLoan = (
  loan: Pick<Loan, 'id' | 'status'>,
  pathKey = 'loan',
  ...params
) => {
  const loanPath = isLoanServicing(loan.status)
    ? `servicing${pathKey.charAt(0).toUpperCase()}${pathKey.slice(1)}`
    : pathKey
  return pathTo(loanPath as keyof typeof routerPath, loan.id, ...params)
}

export const canLinkToLoan = (ability, loan: Pick<Loan, 'status'>) => {
  if (
    ability.can('read', 'servicing') &&
    ['servicing', 'liquidated'].includes(loan.status)
  ) {
    return true
  }

  if (
    ability.can('read', 'reports') &&
    ['archived', 'sold'].includes(loan.status)
  ) {
    return true
  }

  if (
    ability.can('read', 'pipeline') &&
    !['servicing', 'liquidated', 'archived', 'sold'].includes(loan.status)
  ) {
    return true
  }

  return false
}
